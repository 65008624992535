.sobrerip-wrapper {
  padding: 90px 20px;
}

.sobrerip-container {
  max-width: 1170px;
  margin: auto;
  display: flex;
  gap: 40px;
}

.sobrerip-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sobrerip-container div {
  flex: 1;
}

.sobrerip-content h2 {
  font-size: 34px;
  color: #212a34;
}

.sobrerip-content p {
  font-size: 18px;
  line-height: 1.3;
}

.sobrerip-img {
  background-image: url("../../assets/imgs/Coinsecure.webp");
  border-radius: 30px;
  background-size: cover; /* Ajusta o tamanho da imagem para cobrir todo o contêiner */
  background-position: center; /* Centraliza a imagem */
  background-repeat: no-repeat;
}


@media (max-width: 768px) {
  .sobrerip-container {
    padding-top: 10px;
    flex-direction: column;
  }
}
