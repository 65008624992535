.UniversoInformacoes-container {
  background-color: rgba(0, 0, 0, 0.1);
  padding-bottom: 100px;
}

.UniversoInformacoes-container .disclaimer {
  padding: 0 40px;
  max-width: 1210px;
  margin: auto;
}

.UniversoInformacoes-container h2 {
  color: #fff;
}

/* Estilo para a imagem */
.dossier-section-image {
  flex: 1;
  padding: 20px;
}

.dossier-section-image img {
  max-width: 100%;
  height: auto;
}

/* Estilo para o texto */
.dossier-section-text {
  flex: 2;
  padding: 20px;
}

.dossier-section-text h2 {
  font-size: 22px;
  margin-bottom: 15px;
  color: #212a34;
}

.container {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0 20px;
  max-width: 1170px;
  margin: auto;
}

.container .categoria {
  flex: 1;
}

.dossier-section-text li {
  font-size: 18px;
  margin-bottom: 10px;
}

.categoria {
  border-radius: 20px;
  padding: 35px 25px;
  line-height: 1.6;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  background-color: #f1f1f1;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.categoria li {
  list-style: disc;
  color: #212a34;
  margin-left: 20px;
  font-size: 14px;
}

.categoria span {
  color: #707070;
  font-size: 18px;
  margin-left: -3px;
}

@media (max-width: 950px) {
  .container {
    flex-direction: column;
    padding: 0;
  }

  .section-title-main {
    font-size: 1.25em !important;
    padding: 0.8em !important;
    border-radius: 35px !important;
  }

  .UniversoInformacoes-container .disclaimer {
  padding: 0 20px;
}
}
