.depoimentos-container{
    min-height: 380px;
    background-image: url('../../assets/imgs/comp-verb_reduce.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 79% 68%;
    position: relative;
}

.depoimentos-container .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);

}

.depoimentos-container{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.depoimentos-content{
    position: relative;
    z-index: 10;
    color: #fff;
    font-size: 30px;
    font-weight: 400;
}