.pontos-fortes-container{
    background-color: #333;
    color: #f1f1f1;
    display: flex;
    gap: 60px;
    justify-content: center;
    padding: 15px 0;
}

.pontos-fortes-container div{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.pontos-fortes-container i{
    font-size: 45px;
}

.pontos-fortes-container p{
    font-size: 16px;
    line-height: 1.6;
}


@media (max-width: 768px){
    .pontos-fortes-container{
        flex-direction: column;
        padding: 40px 0;
    }
    
}