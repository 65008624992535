.header-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 101;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  transition: 0.3s;
}

.header-container {
  padding: 0 55px !important;
  color: rgba(255, 255, 255, 0.93);
  display: flex;
  align-items: center;
  z-index: 100;
  margin: auto;
  height: 100%;
  gap: 20px;
  justify-content: space-between;
}

.header-container a {
  font-family: 'Cairo', sans-serif;
  font-size: 14px;
  color: #fff !important;
  text-transform: uppercase;
}

.header-container nav {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: center;
}

.contato-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contato-header p {
  color: #fff !important;
}

.header-img {
  width: auto;
  height: 70px;
  cursor: pointer;
}

.header-flex {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.number-copy {
  font-size: 10px;
}

.header-flex svg {
  height: 32px;
  margin-right: 20px;
  color: #fff;
  stroke: #fff;
}

.header-socials {
  display: flex;
  gap: 10px;
  align-items: center;
}

.header-socials i {
  margin-left: 0;
  font-size: 14px;
}

.menu-btn {
  font-size: 40px;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.93);
  display: none;
}

.menu-toggle {
  background-color: transparent;
}

.main-header.scrolled {
  position: fixed;
  background-color: #212a34;
  box-shadow: none;
  border-bottom: none;
  padding: 10px 0;
  transition: 0.3s;
}

.dropdown-wrapper {
  position: relative;
}

.dropdown-content {
  position: absolute;
  left: -30px;
  padding-top: 20px;
}

.dropdown-content a {
  display: block;
  background-color: black;
  padding: 30px;
  white-space: nowrap;
}

.header-container a {
  position: relative;
  text-decoration: none;
  transition: color 0.5s ease, border-bottom-color 0.3s ease;
}

.header-container a:hover {
  text-decoration: underline;
}

.arrow::after {
  display: inline-flex;
  content: "\f107";
  font-family: FontAwesome;
  margin-left: 10px;
  font-size: 11px;
}

@media (max-width: 630px) {
  .header-container {
    padding: 0 20px !important;
  }
}

@media (max-width: 1020px) {
  .header-container nav {
    display: none;
  }

  .menu-btn {
    display: block;
  }
}

@media (max-width: 1095px) {
  .header-socials {
    display: none;
  }

  .header-flex {
    display: none;
  }
}

@media (max-width: 768px) {
  .header-container {
    padding: 10px 20px;
  }
  .header-container nav {
    display: none;
  }

  .header-img {
    height: 60px;
  }
  .header-img-div {
    height: 60px;
  }
  .header-background {
    height: 80px;
  }
}

@media (max-width: 380px) {
  .header-container {
    padding: 10px;
  }

  .header-img-div {
    height: 40px;
  }
  .header-img {
    height: 40px;
  }

  .header-background {
    height: 60px;
  }
}

@media (min-width: 1020px) {
  .menu-toggle {
    display: none;
  }
}

@media (max-width: 1300px) {
  .header-flex {
    display: none;
  }
}
