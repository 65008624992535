.previna-wrapper {
  padding: 90px 20px;
  padding-top: 30px;
}

.previna-container {
  max-width: 1170px;
  margin: auto;
  display: flex;
  flex-direction: row-reverse;
  gap: 40px;
}

.previna-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.previna-container div {
  flex: 1;
}

.previna-content h2 {
  font-size: 34px;
  color: #212a34;
}

.previna-content p {
  font-size: 18px;
  line-height: 1.3;
}

.previna-img {
  background-image: url("../../assets/imgs/shutterstock_1119130634-1024x439.webp");
  border-radius: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .previna-wrapper {
    padding-top: 10px;
  }
  .previna-container {
    flex-direction: column;
  }
}
