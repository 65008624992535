.parceiros-container{
    padding: 0 20px;
    margin-bottom: 90px;
    padding-top: 20px;
}

.parceiros-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  max-width: 1170px;
  margin: auto;
}

.parceiro-card {
  border: 1px solid #ccc;
  padding: 20px;
  min-height: 200px;
  border-radius: 8px;
}

.parceiro-card img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.parceiro-card h3 {
  margin-top: 10px;
}

.parceiro-card p {
  margin-top: 5px;
}
